<template>
  <div class="pa-3">
    <v-overlay
      :absolute="true"
      :value="dataLoading"
      justify-center
      align-center
      opacity="0.2"
    >
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col sm="6" md="3" lg="3" class="py-0">
        <v-menu
          ref="menu"
          v-model="datepickerMenu"
          :close-on-content-click="false"
          :return-value.sync="reportDateRange"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :clearable="true"
              v-model="reportDateRange"
              :label="attributeLabels.reportDateRange"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="clearDateRange"
            ></v-text-field>
          </template>
          <v-date-picker v-model="reportDateRange" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="grey" @click="datepickerMenu = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(reportDateRange);
                setDateRange();
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="6" md="3" lg="3" class="py-0">
        <v-select
          :clearable="true"
          item-text="label"
          item-value="attribute"
          :items="availableAttributes"
          :label="attributeLabels.attribute"
          v-model="filters.attribute"
        ></v-select>
      </v-col>
      <v-col sm="6" md="3" lg="3" class="py-0">
        <v-text-field
          name="oldValue"
          :label="attributeLabels.oldValue"
          v-model="filters.oldValue"
          type="text"
        ></v-text-field>
      </v-col>
      <v-col sm="6" md="3" lg="3" class="py-0">
        <v-text-field
          name="newValue"
          :label="attributeLabels.newValue"
          v-model="filters.newValue"
          type="text"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-iterator
      hide-default-footer
      disable-filtering
      disable-sort
      :items="items"
    >
      <template v-slot:default="props">
        <history-item
          v-for="(item, i) in props.items"
          :key="i"
          :item="item"
        ></history-item>
      </template>
    </v-data-iterator>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <!--    Data table     -->
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { FETCH_HISTORY } from "@/store/actions/account-history";

import HistoryItem from "@/views/account-history/parts/HistoryItem";
import { mapGetters } from "vuex";

export default {
  name: "HistoryList",
  components: { HistoryItem },
  props: {
    gobellsId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataLoading: true,
      loadingText: this.$t("account.listLoading"),
      reportDateRange: [],
      datepickerMenu: false,
      attributeLabels: {
        reportDateRange: "Report date",
        attribute: "Attribute",
        oldValue: "Old value",
        newValue: "New value",
      },
    };
  },
  computed: {
    ...mapGetters("account/history", [
      "items",
      "pageCount",
      "pagination",
      "filters",
    ]),
    availableAttributes: function () {
      let attributes = [];

      const allowedValues = [
        "fullname",
        "domain",
        "birthday",
        "age",
        "userId",
        "gender",
        "language",
        "login",
        "password",
        "type",
        "enabled",
        "stage",
        "errorCode",
        "status",
        "proxy",
        "tags",
        "gobellsId",
      ];
      _.forIn(this.$t("account.attribute"), function (label, attribute) {
        if (allowedValues.indexOf(attribute) > -1) {
          attributes.push({ attribute, label });
        }
      });
      return attributes;
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    filters: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    setDateRange: function () {
      this.filters.reportDateFrom = this.reportDateRange[0];
      this.filters.reportDateTo = this.reportDateRange[1];
    },
    clearDateRange: function () {
      this.filters.reportDateFrom = null;
      this.filters.reportDateTo = null;
    },
    loadItems: function () {
      const { scope, gobellsId, userId } = this;
      this.dataLoading = true;
      this.$store
        .dispatch("account/history/" + FETCH_HISTORY, {
          scope,
          gobellsId,
          userId,
        })
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.pagination.totalItems = 0;
    this.pagination.page = 1;
    this.loadItems();
  },
};
</script>
