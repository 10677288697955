<template>
  <div class="pa-3">
    <v-overlay
      :absolute="true"
      :value="dataLoading"
      justify-center
      align-center
      opacity="0.2"
    >
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <v-menu
      ref="menu"
      v-model="datepickerMenu"
      :close-on-content-click="false"
      :return-value.sync="dateRange"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRange"
          label="Statistics range"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dateRange" range no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="datepickerMenu = false">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.menu.save(dateRange);
            loadItems();
          "
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <bar-chart
      v-if="loaded"
      :chartdata="dataset"
      :options="options"
      :style="chartStyles"
      :height="height"
    ></bar-chart>
  </div>
</template>

<script>
import { FETCH_STATISTIC } from "@/store/actions/account-statistic";
import _ from "lodash";

import { mapGetters } from "vuex";
import moment from "moment";

import BarChart from "@/views/account-stat/BarChart";

const currentDate = moment(new Date());
const filters = {
  reportDateTo: currentDate.format("YYYY-MM-DD"),
  reportDateFrom: currentDate.subtract(7, "days").format("YYYY-MM-DD"),
};

var enumerateDaysBetweenDates = function (startDate, endDate) {
  let currDate = moment(startDate).startOf("day");
  let lastDate = moment(endDate).startOf("day");
  let dates = [currDate.clone().format("YYYY-MM-DD")];

  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.clone().format("YYYY-MM-DD"));
  }

  return dates;
};

export default {
  name: "StatisticList",
  components: { BarChart },
  props: {
    gobellsId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataLoading: true,
      loaded: false,
      datepickerMenu: false,
      options: {
        indexAxis: "y",
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                userCallback: function (label) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
        legend: {
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";
          },
        },
      },
      filter: filters,
      dateRange: [filters.reportDateFrom, filters.reportDateTo],
    };
  },
  computed: {
    ...mapGetters("account/statistic", [
      "items",
      "pageCount",
      "pagination",
      "availableActions",
      "filters",
    ]),
    formattedItems: function () {
      const formattedItems = {};
      this.items.forEach(function (item) {
        item.statistic.forEach(function (statisticItem) {
          _.set(
            formattedItems,
            [statisticItem.name, item.reportDate],
            statisticItem.value
          );
        });
      });
      return formattedItems;
    },
    dataset: function () {
      const labels = enumerateDaysBetweenDates(
        this.filter.reportDateFrom,
        this.filter.reportDateTo
      );
      let datasets = [];
      const component = this;
      this.availableActions.forEach(function (action) {
        const actionData = _.get(component.formattedItems, action.name, {});
        if (!_.isEmpty(actionData)) {
          let formattedActionData = [];
          labels.forEach(function (actionDate) {
            formattedActionData.push(
              parseInt(_.get(actionData, actionDate, 0))
            );
          });
          datasets.push({
            label: component.$t("statistic.action." + action.name),
            data: formattedActionData,
            backgroundColor: action.color,
          });
        }
      });
      return { labels, datasets };
    },
    height: function () {
      const rowCount = enumerateDaysBetweenDates(
        this.filter.reportDateFrom,
        this.filter.reportDateTo
      ).length;
      return rowCount * 30;
    },
    chartStyles: function () {
      return {};
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    dateRange: {
      handler: function () {
        this.filter = {
          reportDateFrom: this.dateRange[0],
          reportDateTo: this.dateRange[1],
        };
      },
      deep: true,
    },
  },
  methods: {
    loadItems: function () {
      this.loaded = false;
      const { scope, gobellsId, userId, filter } = this;
      this.dataLoading = true;
      this.$store
        .dispatch("account/statistic/" + FETCH_STATISTIC, {
          scope,
          gobellsId,
          userId,
          filter,
        })
        .then(
          () => {
            this.dataLoading = false;
            this.loaded = true;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.pagination.totalItems = 0;
    this.pagination.page = 1;
    this.loadItems();
  },
};
</script>
