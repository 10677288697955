<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      {{ $t("account.editPrivacySettings") }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-row>
        <v-col
          v-for="(settingsItem, index) in availableSettings"
          :key="index"
          cols="6"
        >
          <v-select
            persistent-hint
            :clearable="true"
            item-text="title"
            item-value="value"
            :items="getAllowedValues(settingsItem)"
            :label="getLabel(settingsItem)"
            :error-messages="settingsErrors[settingsItem].value"
            v-model="settingsForm[settingsItem].value"
            :hint="
              settingsForm[settingsItem].appliedValue
                ? $t('account.appliedValue', {
                    value: $t(
                      `account.settingsValue.${settingsForm[settingsItem].appliedValue}`
                    ),
                  })
                : ''
            "
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!dataLoading">
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">
        {{ $t("close") }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="updateItem">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import validator from "@/mixin/validator";
import { required } from "vuelidate/lib/validators";
import {
  PATCH_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS,
} from "@/store/actions/donor";
import { mapGetters } from "vuex";

export default {
  name: "AccountSettingsForm",
  mixins: [validator],
  props: {
    account: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      settingsForm: {
        byear: {
          appliedValue: "",
          value: "",
        },
        ownPostsVisibility: {
          appliedValue: "",
          value: "",
        },
        friendsListVisibility: {
          appliedValue: "",
          value: "",
        },
        followingsVisibility: {
          appliedValue: "",
          value: "",
        },
        followersVisibility: {
          appliedValue: "",
          value: "",
        },
        incomingFriendRequests: {
          appliedValue: "",
          value: "",
        },
        allowFollowers: {
          appliedValue: "",
          value: "",
        },
        allowPostComments: {
          appliedValue: "",
          value: "",
        },
        profileInfo: {
          appliedValue: "",
          value: "",
        },
        allowTimelinePosts: {
          appliedValue: "",
          value: "",
        },
        taggedPostsReview: {
          appliedValue: "",
          value: "",
        },
        taggedPostsVisibility: {
          appliedValue: "",
          value: "",
        },
        otherPostsVisibility: {
          appliedValue: "",
          value: "",
        },
        ownPostsTagsReview: {
          appliedValue: "",
          value: "",
        },
        taggedPostsAudience: {
          appliedValue: "",
          value: "",
        },
      },
      attributeLabels: {
        byear: this.$t("account.settings.byear"),
        ownPostsVisibility: this.$t("account.settings.ownPostsVisibility"),
        friendsListVisibility: this.$t(
          "account.settings.friendsListVisibility"
        ),
        followingsVisibility: this.$t("account.settings.followingsVisibility"),
        followersVisibility: this.$t("account.settings.followersVisibility"),
        incomingFriendRequests: this.$t(
          "account.settings.incomingFriendRequests"
        ),
        allowFollowers: this.$t("account.settings.allowFollowers"),
        allowPostComments: this.$t("account.settings.allowPostComments"),
        profileInfo: this.$t("account.settings.profileInfo"),
        allowTimelinePosts: this.$t("account.settings.allowTimelinePosts"),
        taggedPostsReview: this.$t("account.settings.taggedPostsReview"),
        taggedPostsVisibility: this.$t(
          "account.settings.taggedPostsVisibility"
        ),
        otherPostsVisibility: this.$t("account.settings.otherPostsVisibility"),
        ownPostsTagsReview: this.$t("account.settings.ownPostsTagsReview"),
        taggedPostsAudience: this.$t("account.settings.taggedPostsAudience"),
      },
    };
  },
  computed: {
    ...mapGetters("account/donor", ["availablePrivacySettings"]),
    availableSettings: function () {
      return [
        "byear",
        "ownPostsVisibility",
        "friendsListVisibility",
        "followingsVisibility",
        "followersVisibility",
        "incomingFriendRequests",
        "allowFollowers",
        "allowPostComments",
        "profileInfo",
        "allowTimelinePosts",
        "taggedPostsReview",
        "taggedPostsVisibility",
        "otherPostsVisibility",
        "ownPostsTagsReview",
        "taggedPostsAudience",
      ];
    },
    settingsErrors: function () {
      return _.merge(
        {
          byear: {
            value: "",
          },
          ownPostsVisibility: {
            value: "",
          },
          friendsListVisibility: {
            value: "",
          },
          followingsVisibility: {
            value: "",
          },
          followersVisibility: {
            value: "",
          },
          incomingFriendRequests: {
            value: "",
          },
          allowFollowers: {
            value: "",
          },
          allowPostComments: {
            value: "",
          },
          profileInfo: {
            value: "",
          },
          allowTimelinePosts: {
            value: "",
          },
          taggedPostsReview: {
            value: "",
          },
          taggedPostsVisibility: {
            value: "",
          },
          otherPostsVisibility: {
            value: "",
          },
          ownPostsTagsReview: {
            value: "",
          },
          taggedPostsAudience: {
            value: "",
          },
        },
        this.validator.errors.settingsForm
      );
    },
  },
  methods: {
    getLabel: function (attribute) {
      return this.attributeLabels[attribute];
    },
    getAllowedValues: function (attribute) {
      let items = this.availablePrivacySettings(attribute);
      let result = [];
      let vm = this;

      items.forEach(function (item) {
        result.push({
          value: item,
          title: vm.$t(`account.settingsValue.${item}`),
        });
      });
      return result;
    },
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { settingsForm } = this;
        let serializedData = {};
        this.availableSettings.forEach(function (settingsItem) {
          serializedData[settingsItem] = settingsForm[settingsItem].value;
        });
        this.$store
          .dispatch("account/donor/" + PATCH_ACCOUNT_SETTINGS, {
            settings: serializedData,
            type: this.account.target,
            gobellsId: this.account.gobellsId,
            userId: this.account.userId,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    fetchItem: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_SETTINGS, {
          type: this.account.target,
          gobellsId: this.account.gobellsId,
          userId: this.account.userId,
        })
        .then(
          (payload) => {
            this.dataLoading = false;
            if (payload.data) {
              this.settingsForm = payload.data;
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchItem();
  },
  validations: {
    settingsForm: {
      byear: {
        value: { required },
      },
      ownPostsVisibility: {
        value: { required },
      },
      friendsListVisibility: {
        value: { required },
      },
      followingsVisibility: {
        value: { required },
      },
      followersVisibility: {
        value: { required },
      },
      incomingFriendRequests: {
        value: { required },
      },
      allowFollowers: {
        value: { required },
      },
      allowPostComments: {
        value: { required },
      },
      profileInfo: {
        value: { required },
      },
      allowTimelinePosts: {
        value: { required },
      },
      taggedPostsReview: {
        value: { required },
      },
      taggedPostsVisibility: {
        value: { required },
      },
      otherPostsVisibility: {
        value: { required },
      },
      ownPostsTagsReview: {
        value: { required },
      },
      taggedPostsAudience: {
        value: { required },
      },
    },
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
