<template>
  <div class="pa-3">
    <v-overlay
      :absolute="true"
      :value="dataLoading"
      justify-center
      align-center
      opacity="0.2"
    >
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="!dataLoading">
      <h4 class="pl-2 d-flex justify-space-between">
        {{ $t("donorId") }}: {{ info.info ? info.info.donorId : "not set" }}
      </h4>
      <div class="d-flex flex-wrap">
        <account-card
          v-for="(account, i) in info.accounts"
          :key="i"
          :account="account"
        ></account-card>
      </div>
    </div>
  </div>
</template>

<script>
import { FETCH_DONOR_ACCOUNT } from "@/store/actions/donor";
import { mapGetters } from "vuex";

import AccountCard from "@/views/account/card/AccountCard";

export default {
  name: "DonorCard",
  components: { AccountCard },
  props: {
    gobellsId: {
      type: String,
      required: true,
    },
    scope: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      dataLoading: true,
      info: {},
    };
  },
  computed: {
    ...mapGetters("service", ["scopes"]),
  },
  methods: {
    loadItem: function () {
      const { scope, gobellsId } = this;
      scope.push("info");
      this.$store
        .dispatch("account/donor/" + FETCH_DONOR_ACCOUNT, { scope, gobellsId })
        .then(
          (payload) => {
            this.dataLoading = false;
            if (payload.status) {
              this.info = payload.data;
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.loadItem();
  },
};
</script>
