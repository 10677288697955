<template>
  <v-card outlined width="350px" class="mb-3 mr-3">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-2">
          {{ account.userId }}
        </div>
        <v-list-item-title class="mb-1">
          <flag :iso="getAbrByLanguage({ language: account.language })" />
          <v-icon small>
            mdi-gender-{{ getSymbolByGender({ gender: account.gender }) }}
          </v-icon>
        </v-list-item-title>
        <v-list-item-subtitle>
          <p>{{ account.login }}</p>
          <p>
            <v-chip
              small
              outlined
              :color="getColorByStatus({ status: account.status })"
              class="mr-1"
            >
              {{ account.status }}
            </v-chip>
            {{ $t("account.attribute.type") }} {{ account.type }}<br />
            {{ $t("account.stage") }} {{ account.stage }}
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar tile>
        <v-icon x-large>mdi-{{ account.target }}</v-icon>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <router-link
        class="text-decoration-none"
        :to="{
          name: 'AccountPage',
          params: { gobellsId: account.gobellsId, scope: account.target },
        }"
      >
        <v-btn outlined small rounded text> View details </v-btn>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountCard",
  props: {
    account: Object,
  },
  computed: {
    ...mapGetters("account/donor", [
      "getTagColorByCategory",
      "getSymbolByGender",
      "getAbrByLanguage",
      "getColorByStatus",
    ]),
    ...mapGetters("service", ["getServiceName"]),
  },
};
</script>
