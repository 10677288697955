<template>
  <div class="pa-3">
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.userId") }}
        </p>
        <p class="subtitle">
          {{ account.userId }}
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6" v-if="account.domain">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.domain") }}
        </p>
        <p class="subtitle">
          {{ account.domain }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="account.url">
      <v-col xs="12" sm="12" md="12" v-if="account.url">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.url") }}
        </p>
        <p class="subtitle">
          {{ account.url }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="account.proxy">
      <v-col xs="12" sm="12" md="12" v-if="account.proxy">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.proxy") }}
        </p>
        <p class="subtitle">
          {{ account.proxy.address }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.login") }}
        </p>
        <p class="subtitle">
          {{ account.login }}
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.password") }}
        </p>
        <p class="subtitle">
          {{ account.password }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.type") }}:
          <v-chip small outlined>
            {{ account.type }}
          </v-chip>
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p>
          <span class="grey--text body-2"
            >{{ $t("account.attribute.stage") }}:</span
          >
          <span class="subtitle">
            {{ account.stage }}
          </span>
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.status") }}:
          <v-chip
            small
            outlined
            :color="getColorByStatus({ status: account.status })"
          >
            {{ account.status }}
          </v-chip>
        </p>
        <p class="grey--text body-2">
          {{ $t("account.attribute.changedAt") }}:
          {{ account.statusChangedAt }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p>
          <span class="grey--text body-2">
            {{ $t("account.attribute.friendsCount") }}:
          </span>
          <span class="subtitle">
            {{ account.friendsCount }}
          </span>
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p>
          <span class="grey--text body-2">
            {{ $t("account.attribute.errorCode") }}:
          </span>
          <v-icon v-if="account.errorCode > 1" color="red lighten-3">
            mdi-alert-circle
          </v-icon>
          <v-icon v-if="account.errorCode === 1" color="green lighten-3">
            mdi-check-circle
          </v-icon>
          <span v-if="account.errorCode > 1" class="red--text text--lighten-3">
            {{ account.errorCode }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="6" sm="6" md="6" lg="6">
        <p>
          <span class="grey--text body-2">
            {{ $t("account.attribute.enabled") }}:
          </span>
          <v-icon>
            {{ account.enabled ? "mdi-check-circle" : "mdi-close-circle" }}
          </v-icon>
        </p>
      </v-col>
      <v-col xs="6" sm="6" md="6" lg="6">
        <p>
          <span class="grey--text body-2">
            {{ $t("account.attribute.display") }}:
          </span>
          <v-icon>
            {{ account.display ? "mdi-check-circle" : "mdi-close-circle" }}
          </v-icon>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="account.region" cols="12">
        <p class="grey--text body-2 mb-1 mt-3">
          {{ $t("account.attribute.regionId") }}
        </p>
        <div>{{ account.region.title }}</div>
      </v-col>
      <v-col cols="12">
        <p class="grey--text body-2 mb-1 mt-3">
          {{ $t("account.attribute.tags") }}
        </p>
        <div>
          <v-chip
            small
            outlined
            v-for="(tag, i) in account.tags"
            :key="i"
            :color="getTagColorByCategory({ category: tag.type })"
            class="mr-1 mb-2"
          >
            {{ tag.title }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row
      v-if="
        account.birthday ||
        account.age ||
        account.gender ||
        account.fullname ||
        account.language
      "
    >
      <v-col v-if="account.fullname" xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.fullname") }}
        </p>
        <p class="subtitle">
          {{ account.fullname }}
        </p>
      </v-col>
      <v-col v-if="account.age" xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.age") }}
        </p>
        <p class="subtitle">
          {{ account.age }}
        </p>
      </v-col>
      <v-col v-if="account.birthday" xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.birthday") }}
        </p>
        <p class="subtitle">
          {{ account.birthday }}
        </p>
      </v-col>
      <v-col v-if="account.gender" xs="12" sm="12" md="12" lg="6">
        <p>
          <span class="grey--text body-2">
            {{ $t("account.attribute.gender") }}:
          </span>
          <v-icon>
            mdi-gender-{{ getSymbolByGender({ gender: account.gender }) }}
          </v-icon>
        </p>
      </v-col>
      <v-col v-if="account.gender" xs="12" sm="12" md="12" lg="6">
        <p>
          <span class="grey--text body-2">
            {{ $t("account.attribute.language") }}:
          </span>
          <flag :iso="getAbrByLanguage({ language: account.language })" />
        </p>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.createdAt") }}
        </p>
        <p class="subtitle">
          {{ account.createdAt }}
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.updatedAt") }}
        </p>
        <p class="subtitle">
          {{ account.updatedAt }}
        </p>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6">
        <p class="grey--text body-2 mb-0">
          {{ $t("account.attribute.lastActivity") }}
        </p>
        <p class="subtitle">
          {{ account.lastActivity }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountItem",
  props: {
    account: Object,
  },
  computed: {
    title: function () {
      return this.account.fullname
        ? this.account.fullname
        : this.account.domain;
    },
    ...mapGetters("account/donor", [
      "getTagColorByCategory",
      "getSymbolByGender",
      "getAbrByLanguage",
      "getColorByStatus",
    ]),
    ...mapGetters("service", ["getServiceName"]),
  },
};
</script>

<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
