<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      {{ $t("account.edit") }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-2">
      <v-text-field
        name="fullname"
        :label="attributeLabels.fullname"
        :error-messages="accountErrors.fullname"
        v-model="accountForm.fullname"
        type="text"
      ></v-text-field>
      <v-text-field
        name="domain"
        :label="attributeLabels.domain"
        :error-messages="accountErrors.domain"
        v-model="accountForm.domain"
        type="text"
      ></v-text-field>
      <v-menu
        v-model="birthdayModal"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="100px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :label="attributeLabels.birthday"
            :error-messages="accountErrors.birthday"
            v-model="accountForm.birthday"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          v-model="accountForm.birthday"
          @input="birthdayModal = false"
        ></v-date-picker>
      </v-menu>
      <v-autocomplete
        :clearable="true"
        :items="regions"
        item-text="title"
        item-value="id"
        :loading="isRegionLoading"
        :search-input.sync="regionSearch"
        menu-props="closeOnContentClick"
        @change="regionSearch = ''"
        deletable-chips
        small-chips
        hide-no-data
        hide-selected
        name="tags"
        :label="attributeLabels.regionId"
        v-model="accountForm.regionId"
      ></v-autocomplete>
      <v-checkbox
        name="enabled"
        :label="attributeLabels.enabled"
        :error-messages="accountErrors.enabled"
        v-model="accountForm.enabled"
        value="1"
        false-value="0"
      ></v-checkbox>
      <v-autocomplete
        :clearable="true"
        :items="tags"
        item-text="title"
        item-value="id"
        :loading="isTagLoading"
        :search-input.sync="tagSearch"
        menu-props="closeOnContentClick"
        @change="tagSearch = ''"
        deletable-chips
        multiple
        small-chips
        hide-no-data
        hide-selected
        name="tags"
        :label="attributeLabels.tagId"
        v-model="accountForm.tagId"
      ></v-autocomplete>
      <v-alert v-if="tagError" dense outlined type="error">
        {{ $t("account.tagError") }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">
        {{ $t("close") }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="updateItem">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import validator from "@/mixin/validator";
import { maxLength } from "vuelidate/lib/validators";
import { FETCH_REGION_META, PATCH_ACCOUNT } from "@/store/actions/donor";
import { FETCH_TAGS_PARTIAL } from "@/store/actions/tag";
import { TYPE_CONTENT } from "@/store/modules/tag/getters";

export default {
  name: "AccountForm",
  mixins: [validator],
  props: {
    account: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      formErrors: null,
      accountForm: {
        domain: this.account.domain,
        fullname: this.account.fullname,
        regionId: this.account.regionId,
        birthday: this.account.birthday,
        enabled: this.account.enabled ? "1" : "0",
        tagId: _.map(this.account.tags, "id"),
      },
      birthdayModal: false,
      attributeLabels: {
        domain: this.$t("account.attribute.domain"),
        fullname: this.$t("account.attribute.fullname"),
        regionId: this.$t("account.attribute.regionId"),
        birthday: this.$t("account.attribute.birthday"),
        enabled: this.$t("account.attribute.enabled"),
        tagId: this.$t("account.attribute.tags"),
      },
      //Filter values status
      isTagLoading: false,
      isRegionLoading: false,
      //Filter values
      tags: [],
      regions: [],
      //Filter entered value
      tagSearch: null,
      regionSearch: null,
    };
  },
  computed: {
    accountErrors: function () {
      return _.merge(
        {
          fullname: "",
          pathToFile: "",
        },
        this.validator.errors.accountForm
      );
    },
    tagError: function () {
      return this.formErrors && this.formErrors.tagId;
    },
  },
  watch: {
    regionSearch: function (value) {
      this.isRegionLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_REGION_META, {
          title: value,
          ids: this.accountForm.regionId,
        })
        .then(
          (payload) => {
            this.isRegionLoading = false;
            this.regions = payload.data;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    tagSearch: function (value) {
      this.isTagLoading = true;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 50,
          offset: 0,
          search: {
            title: value,
            level: 1,
            selectedIds: this.accountForm.tagId,
            excludeType: [TYPE_CONTENT],
          },
        })
        .then(
          (payload) => {
            this.isTagLoading = false;
            this.tags = payload.data.batch;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { accountForm } = this;
        this.$store
          .dispatch("account/donor/" + PATCH_ACCOUNT, {
            account: accountForm,
            scope: this.account.target,
            gobellsId: this.account.gobellsId,
            userId: this.account.userId,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  mounted: function () {
    this.isTagLoading = true;
    this.$store
      .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
        limit: 50,
        offset: 0,
        search: {
          level: 1,
          selectedIds: this.accountForm.tagId,
          excludeType: [TYPE_CONTENT],
        },
      })
      .then(
        (payload) => {
          this.isTagLoading = false;
          this.tags = payload.data.batch;
        },
        (err) => {
          console.log("Err", err);
        }
      )
      .catch(() => {});

    this.isRegionLoading = true;
    this.$store
      .dispatch("account/donor/" + FETCH_REGION_META, {
        ids: this.accountForm.regionId,
      })
      .then(
        (payload) => {
          this.isRegionLoading = false;
          this.regions = payload.data;
        },
        (err) => {
          console.log("Err", err);
        }
      )
      .catch(() => {});
  },
  validations: {
    accountForm: {
      domain: {
        maxLength: maxLength(255),
      },
      fullname: {
        maxLength: maxLength(128),
      },
    },
  },
};
</script>
