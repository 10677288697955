<template>
  <div>
    <v-overlay
      :absolute="true"
      :value="dataLoading"
      justify-center
      align-center
      opacity="0.1"
    >
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="!dataLoading">
      <v-row>
        <v-col>
          <h1 class="d-flex">
            <v-icon x-large>mdi-{{ scope }}</v-icon>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ account.userId }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </h1>
        </v-col>
        <v-col class="d-flex justify-end pt-8">
          <v-btn
            small
            color="primary"
            @click="syncDialog = true"
            :title="$t('sync')"
            class="mr-2"
          >
            <v-icon small class="mr-1">mdi-sync</v-icon>
          </v-btn>
          <v-btn
            v-if="isFacebook"
            small
            color="primary"
            @click="editSettingsDialog = true"
            :title="$t('account.editPrivacySettings')"
            class="mr-2"
          >
            <v-icon small class="mr-1">mdi-account-cog-outline</v-icon>
          </v-btn>
          <v-btn small color="primary" @click="editDialog = true">
            <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
            {{ $t("account.edit") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-sheet flat outlined class="pa-5 mt-3">
        <v-row>
          <v-col cols="12" lg="6" xl="6">
            <account-view :account="account"></account-view>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" lg="6" xl="6">
            <v-tabs v-model="tabs" color="primary" left>
              <v-tab key="statistic">Statistic</v-tab>
              <v-tab key="donor" v-if="account.donorId">Donor</v-tab>
              <v-tab key="history">History</v-tab>
              <v-tab-item key="statistic">
                <statistic-list
                  v-if="!dataLoading"
                  :scope="scope"
                  :gobellsId="gobellsId"
                  :userId="userId"
                ></statistic-list>
              </v-tab-item>
              <v-tab-item key="donor" v-if="account.donorId">
                <donor-card
                  v-if="!dataLoading"
                  :gobellsId="account.gobellsId"
                  :scope="otherScopes"
                ></donor-card>
              </v-tab-item>
              <v-tab-item key="history">
                <history-list
                  v-if="!dataLoading"
                  :scope="scope"
                  :gobellsId="gobellsId"
                  :userId="userId"
                ></history-list>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-sheet>
    </div>

    <v-dialog v-model="editSettingsDialog" max-width="700px">
      <account-settings-form
        v-if="editSettingsDialog"
        :account="account"
        @close="editSettingsDialog = false"
        @submitted="editSettingsDialog = false"
      ></account-settings-form>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="400px">
      <account-form
        :account="account"
        @close="editDialog = false"
        @submitted="handleUpdate"
      ></account-form>
    </v-dialog>
    <v-dialog v-model="syncDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("syncConfirmation") }}
          <v-btn icon color="white" @click="syncDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{ $t("account.syncAccountConfirmationText", { title }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="syncDialog = false">{{
            $t("close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="syncAccount()">
            {{ $t("sync") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { FETCH_ACCOUNT } from "@/store/actions/donor";
import { FETCH_SERVICES } from "@/store/actions/service";

import { SCOPE_FACEBOOK } from "@/store/modules/service/getters";

import { mapGetters } from "vuex";

import AccountView from "@/views/account/item/AccountView";
import AccountForm from "@/views/account/item/AccountForm";
import AccountSettingsForm from "@/views/account/item/AccountSettingsForm";
import HistoryList from "@/views/account-history/HistoryList";
import StatisticList from "@/views/account-stat/StatisticList";
import DonorCard from "@/views/account/card/DonorCard";
import { SYNC_ACCOUNT } from "../../store/actions/donor";

export default {
  name: "AccountPage",
  components: {
    AccountView,
    AccountForm,
    DonorCard,
    HistoryList,
    StatisticList,
    AccountSettingsForm,
  },
  props: {
    gobellsId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      tabs: null,
      dataLoading: true,
      editDialog: false,
      syncDialog: false,
      editSettingsDialog: false,
      account: {},
    };
  },
  computed: {
    ...mapGetters("service", ["scopes"]),
    title: function () {
      return this.account.fullname ? this.account.fullname : this.account.login;
    },
    isFacebook: function () {
      return this.scope === SCOPE_FACEBOOK;
    },
    otherScopes: function () {
      const scope = this.scope;
      const scopes = JSON.parse(JSON.stringify(this.scopes));
      return _.remove(scopes, function (value) {
        return value !== scope;
      });
    },
  },
  watch: {
    scope: function () {
      this.loadItem();
    },
    gobellsId: function () {
      this.loadItem();
    },
  },
  methods: {
    handleUpdate: function () {
      this.editDialog = false;
      this.loadItem();
    },
    loadItem: function () {
      this.dataLoading = true;
      const { scope, gobellsId, userId } = this;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT, {
          type: scope,
          gobellsId,
          userId,
        })
        .then(
          (payload) => {
            this.dataLoading = false;
            if (payload.status) {
              this.account = _.get(payload, ["data"]);
              this.tabs = "statistics";
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    loadServices: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    syncAccount: function () {
      const { scope, gobellsId, userId } = this;
      this.$store
        .dispatch("account/donor/" + SYNC_ACCOUNT, { scope, gobellsId, userId })
        .then(
          () => {
            this.syncDialog = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.loadServices();
    this.loadItem();
  },
};
</script>
